import SeoHead from '../components/SeoHead';
import { Container } from 'reactstrap';
import { Global } from '@sourceflow-uk/sourceflow-sdk';
import FourOhFour from '../components/FourOFour';
import Header from '../components/MainHeader';
import { Route, Link, Routes } from 'react-dom';

export default function Fourohfour({ global }) {
  return (
    <>
      <SeoHead
        // site_name={`Driving Force`}
        page_name={`404 - This page is not found | Gap Personnel`}
        description={``}
      />
      <Header />
      <FourOhFour
        global={global}
        pathPrefix={`404_page.fourofour`}
      />
    </>
  )
}

export async function getStaticProps({ params }) {
  const global = await Global()

  return { props: { global: global.toJson() } };
}