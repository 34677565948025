import SourceFlowText from '@sourceflow-uk/sourceflowtext';
import React, {
    useRef,
    useEffect
} from "react";
import Link from 'next/link';
import {
    Container
} from "reactstrap";
import styles from './styles.module.scss';


export default function Fourohfoursection({
    global }) {

    const ref = useRef(null);
    React.useEffect(() => {
        import("@lottiefiles/lottie-player");
    });
    return (
        <section className={`${styles.root} position-relative`}>
            <Container>
                <div className='content-wrapper rounded-3 overflow-hidden p-4 pt-5 pb-5'>
                    <article className="d-flex flex-column align-items-center  text-center pt-5 pb-5">
                        <div className="d-none">
                            <SourceFlowText global={global} path="four_oh_four.header" type="html">
                                <h1 className="d-flex flex-column mb-5 d-none"><span className="wrapper">404</span><span>Page Not Found</span></h1>
                            </SourceFlowText>
                        </div>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets9.lottiefiles.com/packages/lf20_kcsr6fcp.json"
                        >
                        </lottie-player>
                        <aside>
                            <SourceFlowText global={global} path="four_oh_four.content" type="html">
                                <p>This is a 404 error, which means you've clicked on a bad link or entered an invalid URL.</p>
                            </SourceFlowText>
                            <Link href="/">
                                <a className="text-decoration-none primaryBtn m-0 d-inline-block mt-3">
                                    Go back home
                                </a>
                            </Link>
                        </aside>
                    </article>
                </div>
            </Container>
        </section>
    )
}